// import { axios } from '../utils/request'
import { axios } from '../utils/request_new'

export function login(data) {
  return axios({
    url: '/admin/login/token',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// 发送验证码
export function msgRegisterSend(data) {
  return axios({
    url: '/msg/register/send',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// (新)发送验证码
export function newSendCode(data) {
  return axios({
    url: `/captcha/verification-code/send`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/* 通过code获取微信开发平台用户信息 */
export function getWxUserInfo(code, type) {
  return axios({
    url: `/weixin/wx/open/user/info?code=${code}&type=${type}`,
    method: 'get',
  })
}

export function getOauth() {
  const data1 = {
    client_id: 'atOgaI7ls6O7xvneorj5n7wF',
    client_secret: 'U1BkIVwAsTWaAmohPM2UUeEpIfBQWocX',
  }
  const data2 = {
    client_id: '7gBZcbsC7kLIWCdELIl8nxcs  ',
    client_secret: '0osTIhce7uPvDKHz6aa67bhCukaKoYl4',
  }
  const data3 = {
    client_id: 'OmvG6bPCaYycRDvW2UQLVmei  ',
    client_secret: '7xZPf7dXJPfvVnijYu0znjEJRKqMAqjb',
  }

  let data
  if (process.env.NODE_ENV === 'test') {
    data = data1
  } else if (process.env.NODE_ENV === 'development') {
    data = data2
  } else {
    data = data3
  }
  return axios({
    url: `/admin/oauth/token`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: {
      grant_type: 'client_credentials',
      ...data,
    },
  })
}

export function bindAccount(params, token) {
  return axios({
    url: `/base/third/bindaccount`,
    method: 'POST',
    params: {
      ...params,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      token: token,
    },
  })
}

/* 设置、修改账号密码 */
export function modifyPassword(data, token) {
  return axios({
    url: `/base/user/update/password`,
    method: 'post',
    params: {
      ...data,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      token: token,
    },
  })
}


/* 用户使用邀请码加入企业组织 */
export function joinCompany(code) {
  let token = sessionStorage.getItem('token')
  return axios({
    url: `/base/inviteCode/startJoin?code=${code}`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
      token: token,
    },
  })
}

/* 获取租户用户信息，判断用户时候已经加入租户 */
export function getUserTid() {
  let token = sessionStorage.getItem('token')
  return axios({
    url: `/base/current/user/my`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      token: token,
    },
  })
}

/* 判断用户是否需要进行登陆验证 */
export function userVerification(query) {
  return axios({
    url: `/captcha/user/verification-type`,
    method: 'get',
    params: {
      ...query,
    },
  })
}

/* 退出登录 */
export function logout() {
  return axios({
    url: `/admin/logout/token`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: {
      token: sessionStorage.getItem('token'),
    },
  })
}

/* 校验用户token是是否失效 */
export function checkToken(token) {
  return axios({
    url: `/admin/token/check`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: {
      token,
    },
  })
}

/* 用户激活（登录后，调用将登陆用的激活状态改为已激活） */
export function activeUser() {
  return axios({
    url: `/base/user/activate`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: {
      userId: sessionStorage.getItem('openid'),
    },
  })
}

export const loginApi = {
  login,
  msgRegisterSend,
  getWxUserInfo,
  getOauth,
  bindAccount,
  modifyPassword,
  joinCompany,
  getUserTid,
  userVerification,
  newSendCode,
  logout,
  checkToken,
  activeUser,
}
