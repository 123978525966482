import Vue from 'vue'
import VueRouter from 'vue-router'
import { checkToken } from '@/api/loginApi'
import CryptoJS from 'crypto-js'
import axios from 'axios'
import { startTimer, clearTimer } from '@/utils/timer'
import { refresh_token_value, get_is_token_expire } from '@/utils/refresh_token'
import { openMyMessage } from '@/components/myMessage/instance'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    redirect: '/publicLogin',
  },
  {
    path: '/publicLogin',
    name: 'publicLogin',
    component: () => import('@/views/publicLogin.vue'),
  },
  {
    path: '/wxbrower',
    name: 'wxbrower',
    component: () => import('@/views/wxbrower.vue'),
  },
  {
    path: '/setPassword',
    name: 'setPassword',
    component: () => import('@/views/setPassword.vue'),
  },
  {
    path: '/connectAccount',
    name: 'connectAccount',
    component: () => import('@/views/connectAccount.vue'),
  },
  {
    path: '/loginSuccess',
    name: 'success',
    component: () => import('@/views/loginSuccess.vue'),
  },
  {
    path: '/selectIdentity',
    name: 'selectIdentity',
    component: () => import('@/views/selectIdentity.vue'),
  },
  {
    path: '/joinCompany',
    name: 'joinCompany',
    component: () => import('@/views/joinCompany.vue'),
    meta: {
      title: '加入组织',
    },
  },
  {
    path: '/submitSuccess',
    name: 'submitSuccess',
    component: () => import('@/views/submitSuccess.vue'),
    meta: {
      title: '申请成功',
    },
  },
  {
    path: '/createCompany',
    name: 'createCompany',
    component: () => import('@/views/createCompany.vue'),
    meta: {
      title: '创建组织',
    },
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    component: () => import('@/views/personalCenter.vue'),
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    redirect: '/userInfo/userInfoDetail',
    component: () => import('@/views/user/userInfo.vue'),
    meta: {
      title: '用户中心',
    },
    children: [
      {
        path: 'userInfoDetail',
        name: 'UserInfoDetail',
        component: () => import('@/views/user/userInfoDetail.vue'),
        meta: {
          title: '个人信息',
        },
      },
      {
        path: 'securityLog',
        name: 'SecurityLog',
        component: () => import('@/views/user/securityLog.vue'),
        meta: {
          title: '安全日志',
        },
      },
      {
        path: 'address',
        name: 'Address',
        component: () => import('@/views/user/address.vue'),
        meta: {
          title: '收货地址',
        },
      },
      {
        path: 'organization',
        name: 'Organization',
        component: () => import('@/views/user/organization.vue'),
        meta: {
          title: '我的组织',
        },
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import('@/views/user/account.vue'),
        meta: {
          title: '登录账号',
        },
      },
      {
        path: 'modifyPassword',
        name: 'ModifyPassword',
        component: () => import('@/views/user/modifyPassword.vue'),
        meta: {
          title: '修改密码',
        },
      },
    ],
  },
  {
    path: '/companyCenter/:id',
    name: 'CompanyCenter',
    component: () => import('@/views/company/companyCenter.vue'),
    meta: {
      title: '企业中心',
    },
  },
  {
    path: '/userInfo_h5',
    name: 'UserInfo_h5',
    component: () => import('@/views/h5/userInfo_h5.vue'),
    meta: {
      title: '用户中心',
    },
  },
  {
    path: '/userInfoDetail_h5',
    name: 'UserInfoDetail_h5',
    component: () => import('@/views/h5/userInfoDetail_h5.vue'),
    meta: {
      title: '个人信息',
    },
  },
  {
    path: '/userInfoModify',
    name: 'UserInfoModify',
    component: () => import('@/views/h5/userInfoModify.vue'),
    meta: {
      title: '个人信息',
    },
  },
  {
    path: '/modifyPassword_h5',
    name: 'ModifyPassword_h5',
    component: () => import('@/views/h5/modifyPassword_h5.vue'),
    meta: {
      title: '修改密码',
    },
  },
  {
    path: '/callzone_about',
    name: 'Callzone_about',
    component: () => import('@/views/h5/callzone_about.vue'),
    meta: {
      title: '关于',
    },
  },
  {
    path: '/organization_h5',
    name: 'Organization_h5',
    component: () => import('@/views/h5/organization_h5.vue'),
    meta: {
      title: '我的组织',
    },
  },
  {
    path: '/address_h5',
    name: 'Address_h5',
    component: () => import('@/views/h5/address_h5.vue'),
    meta: {
      title: '收货地址',
    },
  },
  {
    path: '/account_h5',
    name: 'Account_h5',
    component: () => import('@/views/h5/account_h5.vue'),
    meta: {
      title: '登录账户',
    },
  },
  {
    path: '/accountModify',
    name: 'AccountModify',
    component: () => import('@/views/h5/accountModify.vue'),
    meta: {
      title: '登录账号',
    },
  },
  {
    path: '/securityLog_h5',
    name: 'SecurityLog_h5',
    component: () => import('@/views/h5/securityLog_h5.vue'),
    meta: {
      title: '安全日志',
    },
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach(async (to, from, next) => {
  /* 浏览器标题 */
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + 'CALLZONE'
  } else {
    /* 默认标题 */
    document.title = '登录认证'
  }
  // next()
  console.log(to)

  let whiteRoute = [
    '/publicLogin',
    '/setPassword',
    '/connectAccount',
    '/wxbrower',
    // '/joinCompany',
  ]

  if (whiteRoute.includes(to.path)) {
    console.log('1')
    next()
  } else if (localStorage.getItem('access_token')) {
    console.log('2')
    /* 开启计时，判断是否获取操作 */
    clearTimer()
    startTimer()
    // refresh_token_value()
    if (get_is_token_expire()) {
      openMyMessage()
    } else {
      next()
    }
  } else {
    next({ path: '/publicLogin', query: { redirect: to.fullPath } })
  }
})

/* 判断token是否过期 */
function tokenCheck(token) {
  return new Promise((resolve, reject) => {
    checkToken(token)
      .then((res) => {
        if (res.data.code === 0 && res.data.data == 'true') {
          resolve()
        } else if (res.data.code === 0 && res.data.data == 'false') {
          reject()
        }
      })
      .catch((err) => {
        reject()
      })
  })
}
export default router
