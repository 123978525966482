/*
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-02-29 16:43:33
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-08-13 13:56:09
 * @FilePath: \cz-account-web\src\utils\timer.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { eventBus } from './event-bus'
import {
  refresh_token_value,
  get_is_token_expire,
  isRefreshToken,
  setIsRefreshToken,
  is_refresh_token,
  watcher,
} from '@/utils/refresh_token'
import { openMyMessage } from '@/components/myMessage/instance'

let timer = 0
let lastTimer = 0 /* 最后一次操作的时间戳 */
let intervalId = null /* 计时器 */

function startTimer() {
  timer = 0
  intervalId = setInterval(() => {
    timer++
    eventBus.$emit('timer-update', timer)
    // console.log('被输出值{ 当前计时 }的输出结果是：', timer)

    /* 监听最后一次调用api与token时长距离 */
    // if (get_is_token_expire()) {
    //   openMyMessage()
    // }
    watcher(timer)
  }, 1000) // 每秒更新一次计时器
}

function clearTimer() {
  clearInterval(intervalId)
  timer = 0
}

/* 获取计时器的时间 */
function getTimer() {
  return timer
}

/* 重新计时 */
function resetTimer() {
  lastTimer = Date.now() / 1000
  timer = 0
  // console.log('被输出值{ 最后操作时间 }的输出结果是：', lastTimer)
}

/* 监听鼠标事假，重新计时 */
function handleMouseEvents() {
  document.addEventListener('mousemove', () => {
    lastTimer = Date.now() / 1000
    timer = 0
    // console.log('被输出值{ 最后操作时间 }的输出结果是：', lastTimer)
  })
}

export { startTimer, getTimer, resetTimer, handleMouseEvents, clearTimer }
