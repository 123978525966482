/*
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-02-29 16:42:54
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-02-29 16:43:06
 * @FilePath: \cz-account-web\src\utils\event-bus.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
export const eventBus = new Vue()
