<!--
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-02-28 17:05:03
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-03-01 09:51:09
 * @FilePath: \cz-account-web\src\components\myMessageBox.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="419px"
    :close-on-click-modal="false"
    :show-close="false"
    top="25vh"
  >
    <div slot="title" class="title">
      <img src="~@/assets/img/tip.png" alt="" />提示
    </div>
    <div class="content">
      <img src="~@/assets/image/fileFail.png" alt="" />
      <div>很抱歉，由于您长时间未操作，系统已自动注销</div>
    </div>
    <span slot="footer" class="footer">
      <el-button type="primary" @click="relogin">重新登录</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { clearTimer } from '@/utils/timer'
export default {
  data() {
    return {
      dialogVisible: true,
    }
  },
  props: ['message', 'title', 'options'],
  computed: {
    ...mapState(['showMessageBox', 'message', 'title', 'options']),
  },
  mounted() {
    clearTimer()
  },
  methods: {
    handleConfirm() {
      if (this.options.onConfirm) {
        this.options.onConfirm()
      }
      this.visible = false
    },
    handleCancel() {
      if (this.options.onCancel) {
        this.options.onCancel()
      }
      this.visible = false
    },
    relogin() {
      /* 清空储存信息，重新登陆 */
      sessionStorage.clear()
      localStorage.clear()
      const homePage = window.location.href.includes('companyCenter')
        ? process.env.VUE_APP_BASE_ACCOUNT_URL + '/userInfo/userInfoDetail'
        : window.location.href

      const authUrl =
        process.env.VUE_APP_AUTH_API +
        `/sessions/logout?post_logout_redirect_uri=${homePage}`
      window.location.replace(authUrl)
    },
  },
  statics: {
    show(message, title, options) {
      this.visible = true
      // 其他逻辑
    },
  },
}
</script>

<style lang="less" scoped>
.my-message-box {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
}

/deep/ .el-dialog {
  width: 419px;
  height: 313px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #d4d9d6;
  box-shadow: 0 0 20px #536d5d29;
  .el-dialog__body {
    padding: 15px 20px;
  }
}

.title {
  font-family: 'PingFangSC Medium';
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #292929;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
}

.content {
  font-family: 'PingFangSC';
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
  color: #292929;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 127px;
    height: 120px;
    margin-bottom: 20px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/ .el-button {
    width: 111px;
    height: 32px;
    border-radius: 4px;
    background: #0059ff;
    padding: 0;
  }
}
</style>
