import CryptoJS from 'crypto-js'

export default {
  //加密
  encrypt(word) {
    // 密钥
    const privateKey = CryptoJS.enc.Utf8.parse('21436587A9CBEDF0')
    // 密钥偏移量
    const iv = CryptoJS.enc.Utf8.parse('DEBC9A785634120F')
    let srcs = CryptoJS.enc.Utf8.parse(word)
    let encrypted = CryptoJS.AES.encrypt(srcs, privateKey, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })
    return encrypted.ciphertext.toString()
  },
}

// 解密
export function decrypt(word) {
  // 密钥
  const privateKey = CryptoJS.enc.Utf8.parse('21436587A9CBEDF0')
  // 密钥偏移量
  const iv = CryptoJS.enc.Utf8.parse('DEBC9A785634120F')
  let encryptedData = CryptoJS.enc.Hex.parse(word)
  // 将被加密的数据
  let encryptedStr = CryptoJS.enc.Base64.stringify(encryptedData)
  // 解密
  let decryptedData = CryptoJS.AES.decrypt(encryptedStr, privateKey, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  // 将解密后的数据转换为Utf8格式的字符串
  let plaintext = decryptedData.toString(CryptoJS.enc.Utf8)

  return plaintext.toString()
}
