// import { axios } from '../utils/request'
import { axios } from '../utils/request_new'
import qs from 'qs'

/* 获取行业选择列表 */
export function industryList() {
  return axios({
    url: `/dict/dictData/listnopage`,
    method: 'get',
    params: {
      dictType: 'crm_industry',
      tid: sessionStorage.getItem('tid'),
    },
  })
}


/* 获取租户资源信息列表 */
export function getRenterResource(params) {
  return axios({
    url: `/warestore/v1/resource/subscriptions/overview`,
    method: 'get',
    params
  })
}

/* 查询订阅记录(开通记录) */
export function getSubscriptionsRecords(params) {
  return axios({
    url: `/warestore/v1/resource/subscriptions`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params
  })
}

// 获取资源类型
export function getResourcetypes() {
  return axios({
    url: `/warestore/v1/resource/enums/resource-types`,
    method: 'get',
  })
}

/* 负责人业务标签更改 */
export function updateLeaderBusiness(data) {
  return axios({
    url: `/base/deptLeader/change/area`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

/* 负责人生效开关 */
export function leaderEnable(data) {
  return axios({
    url: `/base/deptLeader/change/enable`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

/* 获取当前租户下的用户数量 */
export function getRenter_userCount() {
  return axios({
    url: `/platform/members-count`,
    method: 'get',
  })
}

/* 获取企业组织的部门数量、基本信息 */
export function getRenter_departmentCount(tid) {
  return axios({
    url: `/platform/departments-count`,
    method: 'get',
  })
}

export function getSonAdminCount(tid) {
  return axios({
    url: `/platform/admin-count`,
    method: 'get',
  })
}


/* 批量修改租户成员的所属部门 */
export function modifyMemberOfDepartment(data) {
  return axios({
    url: '/platform/members/migrate-department',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data
  })
}

/*批量清空租户成员的所属部门 */
export function removeMemberOfDepartment(data) {
  return axios({
    url: '/platform/members/remove-department',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data
  })
}

/* 批量修改租户成员的所属职位 */
export function modifyMemberOfJob(data) {
  return axios({
    url: '/platform/members/change-position',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}
/* 批量清空租户成员的所属职位 */
export function removeMemberOfJob(data) {
  return axios({
    url: '/platform/members/clear-position',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

/* 下载导入租户成员模板文件 */
export function downloadMemberTemplate() {
  return axios({
    url: `/base/member/import-model/download`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    responseType: 'blob',
  })
}

/* 导入租户成员模板文件 */
export function importMemberTemplate(data) {
  return axios({
    url: `/base/import/users`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

/* 获取角色数量（获取超级管理员、子管理员数量） */
export function getRoleCount(params) {
  return axios({
    url: `/base/tenant/role/user/count`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  })
}

/* 获取我的邀请码 */
export function getInviteCode() {
  return axios({
    url: `/base/inviteCode/my`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 获取邀请码携带的租户基本信息 */
export function getInfoByInviteCode(data) {
  return axios({
    url: `/base/inviteCode/search`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

/* 删除成员（删除未激活成员） */
export function deleteMember(data) {
  return axios({
    url: `/base/current/deleteUser`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

/* 获取待审核成员列表 */
export function getWaitAuditList() {
  return axios({
    url: `/base/inviteCodeAudit/list`,
    method: 'get',
    params: {
      tid: sessionStorage.getItem('tid'),
      auditStatus: 0,
    },
  })
}

/* 审核申请成员是否通过加入组织 */
export function auditMemberJoin(data) {
  return axios({
    url: `/base/inviteCode/joinCompany`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

/* 获取群组织，管理员，未激活成员列表 */
export function getUserOfTypeList(params) {
  return axios({
    url: `/base/tenant/users`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  })
}

/* 获取成员分类各个类型的数量 */
export function getTypeCount(tid) {
  return axios({
    url: `/base/tenant/${tid}/member/amount`,
    method: 'get',
  })
}

/* 获取离职成员的历史信息 */
export function getUserHistoryInfo(params) {
  return axios({
    url: `/base/dimissonHistory/list`,
    method: 'get',
    params,
  })
}

/* 获取短信发送记录 */
export function getPushCenterRecordsSms(params) {
  return axios({
    url: `/push/push-center/records/sms`,
    method: 'get',
    params,
  })
}



export const companyApi = {
  industryList,
  updateLeaderBusiness,
  leaderEnable,
  getRenter_userCount,
  getRenter_departmentCount,
  getSonAdminCount,
  modifyMemberOfDepartment,
  removeMemberOfDepartment,
  modifyMemberOfJob,
  removeMemberOfJob,
  downloadMemberTemplate,
  importMemberTemplate,
  getRoleCount,
  getInviteCode,
  getInfoByInviteCode,
  getWaitAuditList,
  deleteMember,
  getUserOfTypeList,
  getTypeCount,
  auditMemberJoin,
  getUserHistoryInfo,
  getSubscriptionsRecords,
  getResourcetypes,
  getRenterResource,
  getPushCenterRecordsSms
}
