/*
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-02-28 18:20:49
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-02-29 09:30:52
 * @FilePath: \cz-account-web\src\components\myMessage\instance.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//instance.js
import Vue from 'vue'
//自定义弹窗组件
import myMessageBox from '@/components/myMessage/myMessageBox.vue'

export function openMyMessage() {
  const constructor = Vue.extend(myMessageBox)
  const instance = new constructor()
  instance.$mount()
  //获取组件内 DOM 插入body
  document.body.appendChild(instance.$el)
}

// export default instance
