// import { axios } from '../utils/request'
import { axios } from '../utils/request_new'

/* 查询用户登录日志列表 */
// export function account_log(params) {
//   return axios({
//     url: `/base/user/account-log`,
//     method: 'get',
//     params: {
//       ...params,
//       userId: sessionStorage.getItem('openid'),
//     },
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
//     },
//   })
// }

/**
 * @description: 通过临时授权码绑定账户和用户
 * @param: {}
 * @date: 2020/10/14 10:57
 */
export function thirdBindaccountTempcode(params) {
  return axios({
    url: '/base/third/bindaccount/tempcode',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
}

/**
 * @description: 微信获取token
 * @param: {}
 * @date: 2020/10/13 14:38
 */
export function wxCheck(params) {
  return axios({
    url: '/admin/wx/check',
    method: 'get',
    params: params
  })
}

/**
 * @description: 获取各场景的二维码信息
 * @param: {}
 * @date: 2020/10/16 11:00
 */
export function wxUtilQrCodeInfo(params) {
  return axios({
    url: '/weixin/wx/util/qrCodeInfo',
    method: 'get',
    params: params
  })
}

/* 获取用户的账号信息(存在哪几种方式账号登录) */
export function getUserAccountMethod() {
  return axios({
    url: `/base/current/user/accounts`,
    method: 'get'
  })
}

/* 当前用户修改账户 */
export function _modifyAccount(data) {
  return axios({
    url: `/base/user/account/modify`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

/* 用户更新手机账号 */
export function modifyMobileAccount(data) {
  return axios({
    url: `/base/current/user/update/mobile`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

/* 校验验证码是否正确 */
export function verifyCode(data) {
  return axios({
    url: `/captcha/verification-code/verify`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

/* 注销用户 */
export function logoutUserAccount() {
  return axios({
    url: `/base/current/deleteUser`,
    method: 'post',
    data: {
      token: sessionStorage.getItem('token'),
      userid: sessionStorage.getItem('openid')
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
}


export const userApi = {
  // account_log,
  thirdBindaccountTempcode,
  wxCheck,
  wxUtilQrCodeInfo,
  getUserAccountMethod,
  _modifyAccount,
  modifyMobileAccount,
  verifyCode,
  logoutUserAccount
}