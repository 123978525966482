// import { axios } from '../utils/auth'
import { axios } from '../utils/request_new'


/* 获取当前用户的账号列表 */
export function getMyAccounts(data) {
  return axios({
    url: '/auth/my-accounts',
    method: 'GET',
  })
}

/* 修改用户密码 */
export function modifyUserPassword(data) {
  return axios({
    url: '/auth/accounts/change-password',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

/* 获得微信公众号二维码及临时token */
export function getWxQrcode(data) {
  return axios({
    url: `/wx/mp/qrcode?scene=${data}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

/* 检查账号 */
export function typeAccount(data) {
  return axios({
    url: `/auth/accounts/check`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 发送绑定手机号验证码 */
export function smsRebindPhoneNumber(data) {
  return axios({
    url: '/auth/sms/rebind-phone-number',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export function smsBindPhoneNumber(data) {
  return axios({
    url: '/auth/sms/bind-account',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 绑定手机号 */
export function bindPhoneNumber(data) {
  return axios({
    url: '/auth/accounts/bind',
    method: 'POST',
    data,
  })
}

/* 换绑手机号 */
export function rebindPhoneNumber(data) {
  return axios({
    url: '/auth/accounts/mobile/rebind',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 重置手机号 */
export function phoneNumberReset(data) {
  return axios({
    url: '/auth/account/phone-number-reset',
    method: 'PUT',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 解绑微信 */
export function unbindWeChat(type) {
  return axios({
    url: `/auth/accounts/third-party/${type}`,
    method: 'DELETE',
  })
}

// /* 重置邮箱 */
export function accountEmailReset(data) {
  return axios({
    url: '/auth/account/email-reset',
    method: 'PUT',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 注销用户 */
export function deactivateUser(data) {
  return axios({
    url: '/auth/accounts/deactivate',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export function getRecentOrganization() {
  return axios({
    url: '/auth/my-recent-login-log',
    method: 'GET',
  })
}

export function getPasswordCheck() {
  return axios({
    url: '/auth/accounts/password-set-check',
    method: 'GET',
  })
}

/* 获得认证记录请求分页 */
export function account_log(params){
  return axios({
    url:  '/auth/my-authentication-requests',
    method: 'GET',
    params
  })
}

export const authApi = {
  modifyUserPassword,
  getMyAccounts,
  typeAccount,
  smsRebindPhoneNumber,
  smsBindPhoneNumber,
  bindPhoneNumber,
  phoneNumberReset,
  getWxQrcode,
  unbindWeChat,
  accountEmailReset,
  rebindPhoneNumber,
  deactivateUser,
  getRecentOrganization,
  getPasswordCheck,
  account_log
}
