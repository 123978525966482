// import { axios } from '../utils/request'
import { axios } from '../utils/request_new'

/* 获取企业中心，管理组织列表 */
export function getMenusSolutionsList() {
  return axios({
    url: '/warestore/v1/menus/solutions',
    method: 'get',
  })
}

// 我的微信收款账户
export function getMyWxReceivingAccount() {
  return axios({
    url: '/market/my-receiving-accounts/wx',
    method: 'get'
  })
}

/* 编辑、更新微信特约商户 */
export function updateWechatMall(data) {
  return axios({
    url: `/market/my-receiving-accounts/wx/bind`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
export const warestoreApi = {
  getMenusSolutionsList,
  getMyWxReceivingAccount,
  updateWechatMall
}
