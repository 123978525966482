import Vue from 'vue'
import Vuex from 'vuex'
import _this from '../main.js'
import { getUserTid, activeUser } from '@/api/loginApi.js'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fromUrl: '',
    userInfo: {},
    accountInfo:[],
    token: '',
    openid: '',
    isWeChatLogin: null,
    isPersonalUser: null,
  },
  getters: {},
  mutations: {
    /* 保存用户是否使用微信登录 */
    saveIsWeChatLogin(state, payload) {
      state.isWeChatLogin = payload
    },
    /* 保存用户进入sso网站的地址 */
    saveFormUrl(state, payload) {
      console.log(state,payload);
      state.fromUrl = payload
      window.localStorage.removeItem('fromUrl')
      localStorage.setItem('fromUrl', payload)
    },
    /* 根据来时的路径参数判断登录是是否选哟选择创建企业 */
    isPersonalUser(state, payload) {
      localStorage.removeItem('isisPersonalUser')
      /* 是否跳转选择企业 */
      if (!payload.includes('isPersonalUser')) {
        state.isPersonalUser = true
        localStorage.setItem('isPersonalUser', true)
      } else {
        let argus = payload.split('isPersonalUser=')[1]
        if (argus.includes('&')) {
          let argusFirst = argus.split('&')[0]
          if (argusFirst == 0) {
            state.isPersonalUser = false
            localStorage.setItem('isPersonalUser', false)
          } else if (argusFirst == 1) {
            state.isPersonalUser = true
            localStorage.setItem('isPersonalUser', true)
          }
        } else {
          if (argus == 0) {
            state.isPersonalUser = false
            localStorage.setItem('isPersonalUser', false)
          } else if (argus == 1) {
            state.isPersonalUser = true
            localStorage.setItem('isPersonalUser', true)
          }
        }
      }
    },
    /* 保存用户信息 */
    saveUserInfo(state, payload) {
      console.log(state,payload);
      state.userInfo = payload
      // activeUser()
    },
    /* 保存账户信息 */
    saveAccountInfo(state, payload) {
      console.log(state,payload);
      state.accountInfo = payload
      // activeUser()
    },
    /* 保存登录成功后用户的token */
    saveUserToken(state, payload) {
      state.token = payload.token
      state.openid = payload.openid
      sessionStorage.setItem('token', payload.token)
      sessionStorage.setItem('openid', payload.openid)
      activeUser()
    },
    /* 携带参数返回登录之前的页面 */
    toFrom(state, payload) {
      /* payload:传入的需要携带的参数组成的对象 */
      // let fromUrl = localStorage.getItem('fromUrl')
      // console.log(state, payload);
      // console.log(fromUrl && fromUrl !== 'false');
      // if (fromUrl && fromUrl !== 'false') {
      //   let keys = Object.keys(payload)
      //   let values = Object.values(payload)
      //   let queryArr = []
      //   for (let i = 0; i < keys.length; i++) {
      //     if (keys[i] == `TK` || keys[i] == 'openId') {
      //       values[i] = _this.$aes.encrypt(values[i])
      //     }
      //     queryArr.push(`${keys[i]}=${values[i]}`)
      //   }
      //   let query = queryArr.join(`&`)
      //   let returnUrl = `${fromUrl}?${query}`.replace(/%3A/g, ':') //将%3A替换成：
      //   // .replace(/%2F/g, '/') //将%2F替换成/

      //   window.open(returnUrl, '_self')
      // } else {
      //   _this.$message.success('登录成功！')
      //   /* 检测用户设备 */
      //   let info = navigator.userAgent
      //   let isPhone = /mobile/i.test(info)
      //   if (isPhone) {
      //     router.push('/userInfo_h5')
      //   } else {
      //     router.push('/userInfo')
      //   }
      // }
    },
    /* 携带参数去别的页面 */
    toOther(state, payload) {
      /* payload:传入的需要携带的参数组成的对象 */
      let toUrl = sessionStorage.getItem('toUrl')
      let keys = Object.keys(payload)
      let values = Object.values(payload)
      let queryArr = []
      for (let i = 0; i < keys.length; i++) {
        if (
          keys[i] == `TK` ||
          keys[i] == 'ID' ||
          keys[i] == 'openid' ||
          keys[i] == 'openId' ||
          keys[i] == 'userid'
        ) {
          values[i] = _this.$aes.encrypt(values[i])
        }
        queryArr.push(`${keys[i]}=${values[i]}`)
      }
      let query = queryArr.join(`&`)
      let returnUrl = `${toUrl}?${query}`.replace(/%3A/g, ':') //将%3A替换成：
      // .replace(/%2F/g, '/') //将%2F替换成/
      window.open(returnUrl, '_blank')
    },
  },
  actions: {
    /* 判断当前登录用户时候已经加入租户 */
    isJoinCompany() {
      // return new Promise((resolve, reject) => {
      //   getUserTid().then((res) => {
      //     if (res.data.code === 0) {
      //       // console.log(res)
      //       let { tid } = res.data.data
      //       /* 保存用户tid */
      //       sessionStorage.setItem('tid', tid)
      //       if (tid == 0) {
      //         resolve(res)
      //       } else {
      //         reject(res)
      //       }
      //     }
      //   })
      // })
    },
  },
  modules: {},
  plugins:[createPersistedState()]
})
