import axios from 'axios' // 导入 axios
import qs from 'qs'
import { openMyMessage } from '@/components/myMessage/instance'
import { resetTimer } from '@/utils/timer'
import {
  refresh_token_value,
  isRefreshToken,
  setIsRefreshToken,
} from '@/utils/refresh_token'

// const { createHash } = require('crypto');
import CryptoJS from 'crypto-js'

console.log(process.env.NODE_ENV,'process.env.NODE_ENV');
function main(uri,data) {
  const appSecretLocal = 'AEB4D99DA310410D97912030CBE5849D'
  const appSecretPre = '2D35A512E468478FADE438F78CEC3413'
  const appSecretPro = 'E9FD50228DEB4333970ABA4AAF9497F8'
  const appKeyLocal = '5BF2DFB6B4BE4A199E848F460EC230D9'
  const appKeyPre = '78E95293E9C24744A5C131309FB0A151'
  const appKeyPro = 'CB42F7138A214C46A9B75B615574AFEF'
  let appSecret = ''
  let appKey = ''
  if (process.env.NODE_ENV == 'test'){
    appSecret = appSecretLocal
    appKey = appKeyLocal
  } else if(process.env.NODE_ENV == 'development'){
    appSecret = appSecretPre
    appKey = appKeyPre
  } else if(process.env.NODE_ENV == 'production'){
    appSecret = appSecretPro
    appKey = appKeyPro
  } 

  // const uri = "/platform/my-profile";
  const parameters = JSON.stringify({
      "alg": "MD5",
      "appKey": appKey,
      "timestamp": String(new Date().getTime())
  });
  const base64Parameters = btoa(parameters); // 使用btoa将参数转换为base64
  const requestBody = data && data.hasOwnProperty('unused') ? '' : data
  const signature = sign(appSecret, base64Parameters, uri, requestBody);
  const Token = base64Parameters + "." + signature ;
  return Token
}

function sign(signKey, base64Parameters, uri, body) {
  const data = base64Parameters + getRelativeURL(uri) + (body ? JSON.stringify(body) : "");
  return CryptoJS.MD5(data + signKey).toString().toUpperCase();
}

function getRelativeURL(uri) {
  // Implement the logic to get relative URL here
  return uri;
}

// 刷新token
async function refreshToken() {
  /* 刷新完token后，重置isRefreshToken变量 */
  setIsRefreshToken(false)
  // clearTimeout(Vue.prototype.$timer)
  const refreshToken = localStorage.getItem('refresh_token')

  const tokenEndpoint = process.env.VUE_APP_AUTH_API + '/oauth2/token'

  try {
    const tokenRequest = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: 'ff',
        grant_type: 'refresh_token',
        scope: 'openid offline_access profile',
        refresh_token: refreshToken,
        // tenant_id: -1,
      }),
    }
    // console.log(tokenRequest)
    // 发送刷新令牌请求
    const tokenResponse = await fetch(tokenEndpoint, tokenRequest).then(
      (response) => response.json()
    )
    // console.log('被输出值{ 刷新令牌 }的输出结果是：', response.data)
    // console.log('被输出值{ 刷新令牌 }的输出结果是：', tokenResponse)
    if (tokenResponse.code == 0) {
      localStorage.setItem('access_token', tokenResponse.data.access_token)
      localStorage.setItem('id_token', tokenResponse.data.id_token)
      localStorage.setItem('refresh_token', tokenResponse.data.refresh_token)
      // 刷新token相关变量值
      refresh_token_value()
    } else if (tokenResponse.code == 4001) {
      // 刷新令牌过期，无法刷新token，重新登录
      openMyMessage()
    }

    return true
  } catch (error) {
    console.error('Failed to refresh token:', error)
    return false
  }
}

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_NEW_URL,
})

//请求在到达服务器之前，先会调用use中的这个回调函数来添加请求头信息
service.interceptors.request.use(async (config) => {
  // if (isTokenExpired()) {
  //   openMyMessage()
  // } else {
  //   await refreshToken()
  // }
  console.log('被输出值{ 是否需要刷新token }的输出结果是：', isRefreshToken)
  if (isRefreshToken) {
    await refreshToken()
  }
  if (config.method === 'get') {
    config.data = { unused: 0 } // 这个是关键点，加入这行就可以了,解决get,请求添加不上Content-Type
  }
  //为请求头对象，添加token验证的Authorization字段
  if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
    config.data = qs.stringify(config.data)
  }
  let token = window.localStorage.getItem('access_token')
  if (
    token &&
    config.url !== '/admin/login/token' &&
    config.url !== '/captcha/user/verification-type'
  ) {
    config.headers['Authorization'] = `Bearer ${token}`
  } else {
    localStorage.removeItem('access_token')
  }
  // if (config.url == '/base/user/account-log') {
  //   config.headers['Content-Type'] =
  //     'application/x-www-form-urlencoded;charset=UTF-8'
  // }
  // console.log(config,'config----fuck');
  config.headers['ShenYu-Authorization'] = main(config.url,config.data)
  config.headers['version'] = '2.0.0'
  return config
})

// 响应拦截
service.interceptors.response.use(
  (response) => {
    resetTimer()
    return response
  },
  (error) => {
    if (error.response.status == 401) {
      // 执行退出操作，例如清除登录状态、跳转到登录页等
      return new Promise((resolve, reject) => {
        openMyMessage()
      })
    }
    return Promise.reject(error.response)
  }
)

export { service as axios }
