/*
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-02-29 17:35:24
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-03-01 15:12:44
 * @FilePath: \cz-account-web\src\utils\refersh_token.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { startTimer, getTimer, resetTimer, handleMouseEvents } from './timer'
import { eventBus } from './event-bus'
import CryptoJS from 'crypto-js'
import { openMyMessage } from '@/components/myMessage/instance'

let is_token_expire = false /* token是否过期 */
let token_expire_time = '' /* token过期时间戳 */
let token_validity_period = 0 /* token有效期（s） */
let isRefreshToken = false /* token是否需要刷新 */

/* 刷新token相关值 */
function refresh_token_value() {
  const token = localStorage.getItem('access_token')
  const [header, payload, signature] = token.split('.') // 分割 JWT token
  const decodedPayload = CryptoJS.enc.Base64url.parse(payload)
  const decodedPayloadString = decodedPayload.toString(CryptoJS.enc.Utf8)
  const payloadJSON = JSON.parse(decodedPayloadString)
  const decoded = payloadJSON
  token_expire_time = decoded.exp
  token_validity_period = decoded.exp - decoded.iat
}

function get_is_token_expire() {
  refresh_token_value()
  is_token_expire = token_expire_time < Date.now() / 1000
  return is_token_expire
}

function is_refresh_token(value) {
  if (token_validity_period - value <= 60) {
    isRefreshToken = true
  }
}

function setIsRefreshToken(boolean) {
  isRefreshToken = boolean
}

function watcher(timer) {
  if (timer > token_validity_period) {
    // 长时间未操作，重新登录
    is_token_expire = true
    openMyMessage()
  } else {
    // 有操作，但是需要刷新toke吗
    // 有效期小于1一分钟，下次调用接口前刷新token
    if (token_expire_time - Date.now() / 1000 <= 60) {
      isRefreshToken = true
    }
  }
}

export {
  refresh_token_value,
  get_is_token_expire,
  isRefreshToken,
  setIsRefreshToken,
  is_refresh_token,
  watcher,
}
