<!--
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-01-29 14:22:23
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-01-31 13:53:54
 * @FilePath: \cz-account-web\src\components\unifyAvatar\unifyAvatar.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="picbox" :style="{ height, 'line-height': height }">
    <img
      :src="picUrl && picUrl.includes('http') ? picUrl : targetAvatar"
      alt=""
      class="c-avatar"
      :style="{ height, width }"
      style="border-radius: 50%"
      :onerror="errorImage"
      v-if="picUrl"
    />
    <div
      v-else
      :style="{ height, width }"
      style="border-radius: 50%"
      class="scriptAvatar"
    >
      {{ name ? name.substr(0, 1) : 'user' }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'unifyAvatar',
  props: {
    picUrl: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '24px',
    },
    height: {
      type: String,
      default: '24px',
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      targetAvatar: '',
      errorImage:
        'this.src="' + require('@/assets/default/default-user.svg') + '"',
    }
  },
}
</script>

<style lang="less" scoped>
// .item {
//   border-radius: 50%;
//   overflow: hidden;
// }

.scriptAvatar {
  background-color: #0059ff;
  color: #fff;
  text-align: center;
}
</style>
